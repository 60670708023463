import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import styles from "./styles/ProductStyles";
import ModalImage from "react-modal-image";
import Wheretobuy from "./Wheretobuy2";
import DescriptionComponent from './DescriptionComponent'
import { Helmet } from 'react-helmet'
  
const SolidBraidNylon = () => {
  const classes = styles();
  return (
    <div>
    
<Helmet>
<title>Solid Braid Nylon</title>
</Helmet>   
      <Typography
          align="center"
          className={classes.pagetitle}
        >
          Solid Braid Nylon
        </Typography>
      <Container maxWidth="md" component="main" className={classes.main}>


        <Grid container spacing={4} justify="center" aignItems="center">
          <Grid item xs={10} md={6} sm={4}>
            <div title="Solid Braid Nylon Rope Reel">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-eighths-x-five-hundred-foot-solid-braid.jpg?v=1646856727"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-eighths-x-five-hundred-foot-solid-braid.jpg?v=1646856727"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Solid Braid Nylon Rope Reel"
            />
            </div>
          </Grid>
          <Grid item xs={10} md={6} sm={4}>
            <div title="Solid Braid Nylon Rope Closeup">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/solid-braid-nylon-close.jpg?v=1659477811"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/solid-braid-nylon-close.jpg?v=1659477811"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Solid Braid Nylon Rope Closeup"
            />
            </div>
          </Grid>
        </Grid>
        <DescriptionComponent 
          product="Solid Braid Nylon"
          subtitle="Sea-Strand nylon ropes are engineered for elasticity and great
          tolerance to withstand shock loads."
          p1="Nylon is UV- and abrasion-resistant, and tolerates chemicals like
          gasoline and oil, making it a solid choice for outdoor applications.
          These ropes are typically used for marine applications like mooring
          lines and anchor ropes, and construction and cargo rigging."
          p2="Our solid-braid nylon rope is smooth-feeling, and is a good choice for
          rope that will be handled often. This rope won’t unravel, even when
          it’s cut, so it’s also perfect for pulleys and winches."
          p3="Solid-braid nylon rope sizes include 1/8”, 3/16”, 1/4”, 5/16”, 3/8”,
          and 1/2”, with lengths of 250 and 500 feet. These ropes are white in
          color."
        />
        <Typography className={classes.pagecontent}>
          
        </Typography>
        <Typography className={classes.pagecontent}>
          
        </Typography>
        <Typography className={classes.pagecontent}>
          
        </Typography>

        <Typography
          align="center"
          className={classes.pagesubtitle}
          gutterBottom
        >
          Solid Braid Nylon Rope
        </Typography>

        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#0060A6" }}>
              <TableRow>
              <TableCell className={classes.theader}>
                  Part Number
                </TableCell>
                <TableCell className={classes.theader}>Diameter</TableCell>
                <TableCell className={classes.theader}>Length</TableCell>
                <TableCell width="170px" className={classes.theader}>
                  Min Breaking Load
                </TableCell>
                <TableCell className={classes.theader}>Weight</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tcell}>91406005</TableCell>
                <TableCell className={classes.tcell}>1/8"</TableCell>
                <TableCell className={classes.tcell}> 500 ft</TableCell>
                <TableCell className={classes.tcell}> 575 lbs</TableCell>
                <TableCell className={classes.tcell}> 4 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}> 91406010</TableCell>
                <TableCell className={classes.tcell}>3/16"</TableCell>
                <TableCell className={classes.tcell}> 500 ft</TableCell>
                <TableCell className={classes.tcell}> 825 lbs</TableCell>
                <TableCell className={classes.tcell}> 6 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91406017</TableCell>
                <TableCell className={classes.tcell}>1/4"</TableCell>
                <TableCell className={classes.tcell}> 250 ft</TableCell>
                <TableCell className={classes.tcell}> 1325 lbs</TableCell>
                <TableCell className={classes.tcell}> 5 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91406020</TableCell>
                <TableCell className={classes.tcell}>1/4"</TableCell>
                <TableCell className={classes.tcell}> 500 ft</TableCell>
                <TableCell className={classes.tcell}> 1325 lbs</TableCell>
                <TableCell className={classes.tcell}> 10 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91406022</TableCell>
                <TableCell className={classes.tcell}> 5/16"</TableCell>
                <TableCell className={classes.tcell}> 250 ft</TableCell>
                <TableCell className={classes.tcell}> 1925 lbs</TableCell>
                <TableCell className={classes.tcell}> 7 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91406025</TableCell>
                <TableCell className={classes.tcell}> 5/16"</TableCell>
                <TableCell className={classes.tcell}> 500 ft</TableCell>
                <TableCell className={classes.tcell}> 1925 lbs</TableCell>
                <TableCell className={classes.tcell}> 14 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}> 91406027</TableCell>
                <TableCell className={classes.tcell}> 3/8"</TableCell>
                <TableCell className={classes.tcell}> 250 ft</TableCell>
                <TableCell className={classes.tcell}> 2800 lbs</TableCell>
                <TableCell className={classes.tcell}> 9 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}> 91406030</TableCell>
                <TableCell className={classes.tcell}> 3/8"</TableCell>
                <TableCell className={classes.tcell}> 500 ft</TableCell>
                <TableCell className={classes.tcell}> 2800 lbs</TableCell>
                <TableCell className={classes.tcell}> 17 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91406037</TableCell>
                <TableCell className={classes.tcell}>1/2"</TableCell>
                <TableCell className={classes.tcell}> 250 ft</TableCell>
                <TableCell className={classes.tcell}> 4200 lbs</TableCell>
                <TableCell className={classes.tcell}> 17 lbs</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Wheretobuy />
    </div>
  );
};

export default SolidBraidNylon;
