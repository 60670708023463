import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import styles from "./styles/ProductStyles";
import ModalImage from "react-modal-image";
import Wheretobuy from "./Wheretobuy2";
import DescriptionComponent from './DescriptionComponent'
import { Helmet } from 'react-helmet'

const PolypropyleneRope = () => {
  const classes = styles();
  return (
    <div>
      
<Helmet>
<title>Polypropylene Rope</title>
</Helmet>   
        <Typography
          align="center"
          className={classes.pagetitle}
        >
          Polypropylene Rope
        </Typography>
      <Container maxWidth="md" component="main" className={classes.main}>

        <Grid container spacing={4} justify="center" aignItems="center">
          <Grid item xs={10} md={6} sm={4}>
            <div title="Polypropylene Rope Reel">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-quarter-x-three-hundred-polypropylene.jpg?v=1646856711"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-quarter-x-three-hundred-polypropylene.jpg?v=1646856711"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Polypropylene Rope Reel"
            />
            </div>
          </Grid>
          <Grid item xs={10} md={6} sm={4}>
            <div title="Polypropylene Rope Closeup">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/hollow-braid-polypro-close.jpg?v=1659477810"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/hollow-braid-polypro-close.jpg?v=1659477810"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Polypropylene Rope Closeup"
            />
            </div>
          </Grid>
        </Grid>
        <DescriptionComponent 
          product="Polypropylene Rope"
          subtitle=" Sea-Strand polypropylene rope is designed to be a good multi-purpose
          utility rope."
          p1="Polypropylene is lightweight, and makes a good material for ropes that
          float. Polypropylene will not rot, won’t shrink, and is very resistant
          to most chemicals, including oil and gasoline."
          p2="Sea-Strand polypropylene ropes are available as 3-strand twisted or
          hollow-braid lines if you need less torque for your application."
          p3="Hollow braid polypropylene rope sizes include 1/4” and 3/8”, and
          lengths of 300 and 600 feet. These ropes are yellow in color, so it’s
          easy to spot and recover these floating ropes during marine
          operations."
          p4=" 3-strand twisted polypropylene rope sizes include 1/4”, 5/16”, 3/8”,
          1/2”, 5/8”, 3/4”, and 1”, with lengths of 300 and 600 feet. These
          ropes are available in either tan or yellow colors."
        />
        <Typography className={classes.pagecontent}>
          
        </Typography>
        <Typography className={classes.pagecontent}>
          
        </Typography>
        <Typography className={classes.pagecontent}>
          
        </Typography>
        <Typography className={classes.pagecontent}>
         
        </Typography>

        <Typography
          align="center"
          className={classes.pagesubtitle}
          gutterBottom
        >
          Yellow 3-Strand Rope
        </Typography>

        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#0060A6" }}>
              <TableRow>
              <TableCell className={classes.theader}>
                  Part Number
                </TableCell>
                <TableCell className={classes.theader}>
                  Diameter
                </TableCell>
                <TableCell className={classes.theader}>
                  Length
                </TableCell>
                <TableCell width="170px" className={classes.theader}>
                  Min Breaking Load
                </TableCell>
                <TableCell className={classes.theader}>
                  Weight
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tcell}>91401050</TableCell>
                <TableCell className={classes.tcell}>1/4"</TableCell>
                <TableCell className={classes.tcell}>600 ft</TableCell>
                <TableCell className={classes.tcell}>1125 lbs</TableCell>
                <TableCell className={classes.tcell}>7.20 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91401060</TableCell>
                <TableCell className={classes.tcell}>5/16"</TableCell>
                <TableCell className={classes.tcell}>600 ft</TableCell>
                <TableCell className={classes.tcell}>1710 lbs</TableCell>
                <TableCell className={classes.tcell}>10.80 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91401065</TableCell>
                <TableCell className={classes.tcell}>3/8"</TableCell>
                <TableCell className={classes.tcell}>600 ft</TableCell>
                <TableCell className={classes.tcell}>2430 lbs</TableCell>
                <TableCell className={classes.tcell}>16.80 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91401067</TableCell>
                <TableCell className={classes.tcell}>1/2"</TableCell>
                <TableCell className={classes.tcell}>300 ft</TableCell>
                <TableCell className={classes.tcell}>3780 lbs</TableCell>
                <TableCell className={classes.tcell}>14.00 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91401070</TableCell>
                <TableCell className={classes.tcell}>1/2"</TableCell>
                <TableCell className={classes.tcell}>600 ft</TableCell>
                <TableCell className={classes.tcell}>3780 lbs</TableCell>
                <TableCell className={classes.tcell}>28.20 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91401072</TableCell>
                <TableCell className={classes.tcell}>5/8"</TableCell>
                <TableCell className={classes.tcell}>300 ft</TableCell>
                <TableCell className={classes.tcell}>5580 lbs</TableCell>
                <TableCell className={classes.tcell}>18.40 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91401075</TableCell>
                <TableCell className={classes.tcell}>5/8"</TableCell>
                <TableCell className={classes.tcell}>600 ft</TableCell>
                <TableCell className={classes.tcell}>5580 lbs</TableCell>
                <TableCell className={classes.tcell}>45.00 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91401077</TableCell>
                <TableCell className={classes.tcell}>3/4"</TableCell>
                <TableCell className={classes.tcell}>300 ft</TableCell>
                <TableCell className={classes.tcell}>7650 lbs</TableCell>
                <TableCell className={classes.tcell}>26.50 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91401080</TableCell>
                <TableCell className={classes.tcell}>3/4"</TableCell>
                <TableCell className={classes.tcell}>600 ft</TableCell>
                <TableCell className={classes.tcell}>7650 lbs</TableCell>
                <TableCell className={classes.tcell}>64.20 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91401082</TableCell>
                <TableCell className={classes.tcell}>1"</TableCell>
                <TableCell className={classes.tcell}>300 ft</TableCell>
                <TableCell className={classes.tcell}>12825 lbs</TableCell>
                <TableCell className={classes.tcell}>46.00 lbs</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>


        <Typography
          align="center"
          className={classes.pagesubtitle}
          gutterBottom
        >
          Tan 3-Strand Rope
        </Typography>
        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#0060A6" }}>
              <TableRow>
              <TableCell className={classes.theader}>
                  Part Number
                </TableCell>
                <TableCell className={classes.theader}>
                  Diameter
                </TableCell>
                <TableCell className={classes.theader}>
                  Length
                </TableCell>
                <TableCell className={classes.theader}>
                  Min Breaking Load
                </TableCell>
                <TableCell className={classes.theader}>
                  Weight
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tcell}>91401105</TableCell>
                <TableCell className={classes.tcell}>3/8"</TableCell>
                <TableCell className={classes.tcell}>600 ft</TableCell>
                <TableCell className={classes.tcell}>2430 lbs</TableCell>
                <TableCell className={classes.tcell}>13 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91401110</TableCell>
                <TableCell className={classes.tcell}>1/2"</TableCell>
                <TableCell className={classes.tcell}>300 ft</TableCell>
                <TableCell className={classes.tcell}>3780 lbs</TableCell>
                <TableCell className={classes.tcell}>14.00 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91401115</TableCell>
                <TableCell className={classes.tcell}>5/8"</TableCell>
                <TableCell className={classes.tcell}>300 ft</TableCell>
                <TableCell className={classes.tcell}>5580 lbs</TableCell>
                <TableCell className={classes.tcell}>18.40 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91401120</TableCell>
                <TableCell className={classes.tcell}>3/4"</TableCell>
                <TableCell className={classes.tcell}>300 ft</TableCell>
                <TableCell className={classes.tcell}>7650 lbs</TableCell>
                <TableCell className={classes.tcell}>26.50 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91401125</TableCell>
                <TableCell className={classes.tcell}>1"</TableCell>
                <TableCell className={classes.tcell}>300 ft</TableCell>
                <TableCell className={classes.tcell}>12825 lbs</TableCell>
                <TableCell className={classes.tcell}>46.00 lbs</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        
        <Typography
          align="center"
          className={classes.pagesubtitle}
          gutterBottom
        >
          Hollow Braid
        </Typography>
        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#0060A6" }}>
              <TableRow>
              <TableCell className={classes.theader}>
                  Part Number
                </TableCell>
                <TableCell className={classes.theader}>
                  Diameter
                </TableCell>
                <TableCell className={classes.theader}>
                  Length
                </TableCell>
                <TableCell className={classes.theader}>
                  Min Breaking Load
                </TableCell>
                <TableCell className={classes.theader}>
                  Weight
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tcell}>91401502</TableCell>
                <TableCell className={classes.tcell}>1/4"</TableCell>
                <TableCell className={classes.tcell}>300 ft</TableCell>
                <TableCell className={classes.tcell}>440 lbs</TableCell>
                <TableCell className={classes.tcell}>2.7 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91401504</TableCell>
                <TableCell className={classes.tcell}>1/4"</TableCell>
                <TableCell className={classes.tcell}>600 ft</TableCell>
                <TableCell className={classes.tcell}>440 lbs</TableCell>
                <TableCell className={classes.tcell}>5.4 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91401507</TableCell>
                <TableCell className={classes.tcell}>3/8"</TableCell>
                <TableCell className={classes.tcell}>300 ft</TableCell>
                <TableCell className={classes.tcell}>1850 lbs</TableCell>
                <TableCell className={classes.tcell}>6 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91401509</TableCell>
                <TableCell className={classes.tcell}>3/8"</TableCell>
                <TableCell className={classes.tcell}>600 ft</TableCell>
                <TableCell className={classes.tcell}>1850 lbs</TableCell>
                <TableCell className={classes.tcell}>12 lbs</TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Wheretobuy />
    </div>
  );
};

export default PolypropyleneRope;
