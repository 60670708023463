import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  pagetitle: {
    paddingBottom: "35px",
    fontSize: "50px",
    fontWeight: "700",
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
      padding: "20px",
    },
  },
  pagesubtitle: {
    paddingBottom: "40px",
  },
  img: {
    height: "50%",
    width: "50%",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .3s ease",
    },
  },
  imgProduct: {
    height: "86px",
    [theme.breakpoints.down("sm")]: {
      height: "45px"
    },
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .3s ease",
    },
   
  },
}));