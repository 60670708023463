import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import styles from "./styles/ProductStyles";
import ModalImage from "react-modal-image";
import Wheretobuy from "./Wheretobuy2";
import DescriptionComponent from './DescriptionComponent'
import { Helmet } from 'react-helmet'

const NylonRope = () => {
  const classes = styles();
  return (
    <div>
      
<Helmet>
<title>3-Strand Nylon</title>
</Helmet>   
      <Typography
        align="center"
        className={classes.pagetitle}
        >
          Nylon Rope
        </Typography>
      <Container maxWidth="md" component="main" className={classes.main}>
        <Grid container spacing={4} justify="center" aignItems="center">
          <Grid item xs={10} md={6} sm={4}>
            <div title="Three quarter by six hundred foor three strand rope">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-quarter-x-six-hundred-foot-three-strand.jpg?v=1646856705"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-quarter-x-six-hundred-foot-three-strand.jpg?v=1646856705"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Three quarter by six hundred foor three strand rope"
            />
            </div>
          </Grid>
          <Grid item xs={10} md={6} sm={4}>
            <div title="Three strand nylon rope close up">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/3-strand-nylon-close.jpg?v=1659477808"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/3-strand-nylon-close.jpg?v=1659477808"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Three strand nylon rope close up"
            />
            </div>
          </Grid>
        </Grid>

        <DescriptionComponent 
          product="Nylon Rope" 
          subtitle=" Sea-Strand nylon ropes are engineered for elasticity and great
          tolerance to withstand shock loads."
          p1="Nylon is UV- and abrasion-resistant, and tolerates chemicals like
          gasoline and oil, making it a solid choice for outdoor applications.
          Nylon ropes make fantastic heavy-duty multi-purpose ropes with
          thousands of uses. The smooth fibers make nylon a good choice for rope
          that will be handled often."
          p2="Sea-Strand offers double-braid and solid-braid 100% nylon ropes, as
          well as nylon-polyester blend in a 3-strand twisted construction."
          p3="Nylon-polyester rope sizes include 1/4”, 5/16”, 3/8”, 1/2”, 5/8”,
          3/4”, and 1”, with lengths of 200, 300, 400, and 600 feet. These ropes
          are white in color."
          p4="Nylon solid-braid rope sizes include 1/8”, 3/16”, 1/4”, 5/16”, 3/8”,
          and 1/2”, with lengths of 250 and 500 feet. These ropes are white in
          color."
          p5="Nylon double-braid rope sizes include 1/4”, 3/8”, 1/2”, 5/8”, and
          3/4”, with lengths of 300 and 600 feet. These ropes are white with
          tracers."
          />
        

        <Typography
          align="center"
          className={classes.pagesubtitle}
          gutterBottom
        >
          Nylon Polyester 3-Strand Rope
        </Typography>

        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#0060A6" }}>
              <TableRow>
              <TableCell className={classes.theader}>
                  Part Number
                </TableCell>
                <TableCell className={classes.theader}>
                  Diameter
                </TableCell>
                <TableCell className={classes.theader}>
                  Length
                </TableCell>
                <TableCell width="170px" className={classes.theader}>
                  Min Breaking Load
                </TableCell>
                <TableCell className={classes.theader}>
                  Weight
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
             
             <TableCell className={classes.tcell}>91400005 	</TableCell>
             <TableCell className={classes.tcell}> 1/4"	</TableCell>
              <TableCell className={classes.tcell}>600 ft	</TableCell>
              <TableCell className={classes.tcell}>1485 lbs</TableCell>
              <TableCell className={classes.tcell}>	10 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}> 91400010	</TableCell>
              <TableCell className={classes.tcell}>5/16"	</TableCell>
              <TableCell className={classes.tcell}>600 ft	</TableCell>
              <TableCell className={classes.tcell}>2295 lbs</TableCell>
              <TableCell className={classes.tcell}>	18 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91400012</TableCell>
              <TableCell className={classes.tcell}>3/8"	</TableCell>
              <TableCell className={classes.tcell}>200 ft	</TableCell>
              <TableCell className={classes.tcell}>3240 lbs</TableCell>
              <TableCell className={classes.tcell}>	8 lbs</TableCell>
              </TableRow>
              <TableRow>
             
             <TableCell className={classes.tcell}>91400014</TableCell>
             <TableCell className={classes.tcell}> 3/8"	</TableCell>
              <TableCell className={classes.tcell}>400 ft	</TableCell>
              <TableCell className={classes.tcell}>3240 lbs</TableCell>
              <TableCell className={classes.tcell}>	16 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}> 91400015</TableCell>
              <TableCell className={classes.tcell}>3/8"</TableCell>
              <TableCell className={classes.tcell}>	600 ft	</TableCell>
              <TableCell className={classes.tcell}>3240 lbs</TableCell>
              <TableCell className={classes.tcell}>	24 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91400017	</TableCell>
              <TableCell className={classes.tcell}>1/2"	</TableCell>
              <TableCell className={classes.tcell}>200 ft	</TableCell>
              <TableCell className={classes.tcell}>5670 lbs</TableCell>
              <TableCell className={classes.tcell}>	20 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91400019</TableCell>
              <TableCell className={classes.tcell}>1/2"	</TableCell>
              <TableCell className={classes.tcell}>400 ft	</TableCell>
              <TableCell className={classes.tcell}>5670 lbs</TableCell>
              <TableCell className={classes.tcell}>	40 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91400020	</TableCell>
              <TableCell className={classes.tcell}>1/2"	</TableCell>
              <TableCell className={classes.tcell}>600 ft	</TableCell>
              <TableCell className={classes.tcell}>5670 lbs</TableCell>
              <TableCell className={classes.tcell}>	60 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91400022	</TableCell>
              <TableCell className={classes.tcell}>5/8"	</TableCell>
              <TableCell className={classes.tcell}>200 ft	</TableCell>
              <TableCell className={classes.tcell}>8910 lbs</TableCell>
              <TableCell className={classes.tcell}>	21 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}> 91400024	</TableCell>
              <TableCell className={classes.tcell}>5/8"	</TableCell>
              <TableCell className={classes.tcell}>400 ft</TableCell>
              <TableCell className={classes.tcell}>	8910 lbs</TableCell>
              <TableCell className={classes.tcell}>	42 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91400025</TableCell>
              <TableCell className={classes.tcell}>5/8"	</TableCell>
              <TableCell className={classes.tcell}>600 ft</TableCell>
              <TableCell className={classes.tcell}>	8910 lbs</TableCell>
              <TableCell className={classes.tcell}>	63 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91400027</TableCell>
              <TableCell className={classes.tcell}>3/4"	</TableCell>
              <TableCell className={classes.tcell}>200 ft	</TableCell>
              <TableCell className={classes.tcell}>12780 lbs	</TableCell>
              <TableCell className={classes.tcell}>30 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91400029</TableCell>
              <TableCell className={classes.tcell}>3/4"	</TableCell>
              <TableCell className={classes.tcell}>400 ft</TableCell>
              <TableCell className={classes.tcell}>	12780 lbs	</TableCell>
              <TableCell className={classes.tcell}>70 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91400030</TableCell>
              <TableCell className={classes.tcell}>3/4"	</TableCell>
              <TableCell className={classes.tcell}>600 ft</TableCell>
              <TableCell className={classes.tcell}>	12780 lbs	</TableCell>
              <TableCell className={classes.tcell}>90 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91400032</TableCell>
              <TableCell className={classes.tcell}>1"	</TableCell>
              <TableCell className={classes.tcell}>300 ft	</TableCell>
              <TableCell className={classes.tcell}>22230 lbs	</TableCell>
              <TableCell className={classes.tcell}>87 lbs</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Wheretobuy />
    </div>
  );
};

export default NylonRope;
