import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import styles from "./styles/ProductStyles";
import ModalImage from "react-modal-image";
import Wheretobuy from "./Wheretobuy2";
import DescriptionComponent from "./DescriptionComponent";
import { Helmet } from 'react-helmet'

const SeaStrandX = () => {
  const classes = styles();
  return (
    <div>
     
<Helmet>
<title>Sea-Strand X</title>
</Helmet>   
        <Typography
          align="center"
          className={classes.pagetitle}
        >
          Sea-Strand X
        </Typography>
      <Container maxWidth="lg" component="main" className={classes.main} style={{marginBottom: 0}}>
        <Typography
          className={classes.pagesubtitle}
          style={{ textAlign: "center" }}
        >
          
        </Typography>
        <Grid container spacing={4} justify="center" aignItems="center">
          <Grid item xs={10} md={3} sm={4}>
            <div title="Blue Sea Strand X Rope">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/products/blue-sea-strand-x-rope.jpg"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/products/blue-sea-strand-x-rope.jpg"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Blue Sea Strand X Rope"
            />
            </div>
          </Grid>
          <Grid item xs={10} md={3} sm={4}>
            <div title="Gray Sea Strand X Rope">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/products/gray-sea-strand-x-rope.jpg"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/products/gray-sea-strand-x-rope.jpg"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Gray Sea Strand X Rope"
            />
            </div>
          </Grid>
          <Grid item xs={10} md={3} sm={4}>
            <div title="Red Sea Strand X Rope">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/products/red-sea-strand-x-rope.jpg"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/products/red-sea-strand-x-rope.jpg"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Red Sea Strand X Rope"
            />
            </div>
          </Grid>
          <Grid item xs={10} md={3} sm={4}>
            <div title="Orange Sea Strand X Rope">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/products/orange-sea-strand-x-rope.jpg"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/products/orange-sea-strand-x-rope.jpg"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Orange Sea Strand X Rope"
            />
            </div>
          </Grid>
        </Grid>
        
        <Container maxWidth="md" component="main" className={classes.main} style={{marginTop: 0}}>
          <DescriptionComponent 
            product="Sea-Strand X"
            subtitle="Our Sea-Strand X high-performance rope is a precision-engineered
            UHMWPE rope (Ultra-high Molecular Weight Polyethylene rope), the
            equivalent of brands like Dyneema and Spectra rope."
            p1=" These polyethylene ropes are extremely strong but very light, with a
          strength-to-weight ratio about eight times that of high-strength
          steel."
            p2=" UHMWPE ropes have great wear resistance and low stretch, making them
          suitable alternatives to wire rope in many applications. Sea-Strand X
          is very soft and flexible, making for easy handling and splicing.
          Since the fibers discolor when damaged, this rope is also very easy to
          inspect. Since UHMWPE rope floats and repels water, it’s a great
          choice for marine environments."
            p3="UHWMPE ropes are typically used for industrial applications like
          rigging, winching, and lifting, but also sail rigging, mountain
          climbing, and motorsports. Since it has a very low stretch under load,
          Sea-Strand X rope is safer than steel cable, since it will not snap
          back wildly when broken."
            p4=" Our Sea-Strand X ropes are available in 3/16”, 1/4”, 5/16”, 3/8”,
          7/16”, and 1/2”. These ropes are available by the foot for custom
          lengths. These ropes come in blue, grey, orange, and red colors."
          />


        <Typography
          align="center"
          className={classes.pagesubtitle}
          gutterBottom
        >
          Sea-Strand X Rope
        </Typography>

        <TableContainer component={Paper}  style={{ marginBottom: "15px", width: "90%",margin: "0 auto" }}>
          <Table
            
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
            
          >
            <TableHead style={{ backgroundColor: "#0060A6" }}>
              <TableRow>
                <TableCell className={classes.theader}>
                 Part Number
                </TableCell>
                <TableCell className={classes.theader}>
                 Size
                </TableCell>
                <TableCell width="170px" className={classes.theader}>
                  Min Breaking Load
                </TableCell>
                <TableCell className={classes.theader}>
                  Weight
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
              <TableCell className={classes.tcell}>91406103	</TableCell>
                <TableCell className={classes.tcell}>3/16"	</TableCell>
                <TableCell className={classes.tcell}>5000 lbs	</TableCell>
                <TableCell className={classes.tcell}>0.01 lbs per foot</TableCell>
              </TableRow>
              <TableRow>
              
                <TableCell className={classes.tcell}>91406113	</TableCell>
                <TableCell className={classes.tcell}>1/4"	</TableCell>
                <TableCell className={classes.tcell}>8000 lbs	</TableCell>
                <TableCell className={classes.tcell}>0.02 lbs per foot</TableCell>

              </TableRow>
              <TableRow>
              
                <TableCell className={classes.tcell}>91406123	</TableCell>
                <TableCell className={classes.tcell}>5/16"	</TableCell>
                <TableCell className={classes.tcell}>12600 lbs	</TableCell>
                <TableCell className={classes.tcell}>0.03 lbs per foot</TableCell>

              </TableRow>
              <TableRow>
              
                <TableCell className={classes.tcell}>91406133	</TableCell>
                <TableCell  className={classes.tcell}>3/8"	</TableCell>
                <TableCell  className={classes.tcell}>18000 lbs	</TableCell>
                <TableCell  className={classes.tcell}>0.03 lbs per foot</TableCell>

              </TableRow>
              <TableRow>
              
                <TableCell className={classes.tcell}> 91406143	</TableCell>
                <TableCell  className={classes.tcell}>7/16"	</TableCell>
                <TableCell  className={classes.tcell}>25000 lbs	</TableCell>
                <TableCell  className={classes.tcell}>0.05 lbs per foot</TableCell>

              </TableRow>
              <TableRow>
              
                <TableCell className={classes.tcell}>91406153</TableCell>
                <TableCell  className={classes.tcell}>1/2"	</TableCell>
                <TableCell  className={classes.tcell}>32000 lbs	</TableCell>
                <TableCell  className={classes.tcell}>0.06 lbs per foot</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        </Container>
      </Container>
      <Wheretobuy />
    </div>
  );
};

export default SeaStrandX;
