import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {Link} from 'react-router-dom'
import { AppBar, Toolbar, Grid, Box,  } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import logo from '../assets/invlogo.png'

const useStyles = makeStyles({
  list: {
    width: 230,
  },
  fullList: {
    width: 'auto',
  },
  color: {
    backgroundColor: '#0060a6',
},
reslogo: {
    height: '25px',
    paddingTop: '8px'   
},
reslink: {
    textDecoration: 'none',
    color: '#0060A6',
    fontWeight: 'bold',
    fontSize: '17px',
    fontFamily: 'Lato',
},
reslink2: {
    textDecoration: 'none',
    color: '#0060A6',
    fontSize: '16px',
    fontFamily: 'Lato',
},

});

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({    
    left: false,    
  });

  const toggleDrawer = (anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = anchor => (
    <div className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>      
              <ListItem key = {1} button divider>
               <Link to="/manila-rope" className={classes.reslink}>Manila Rope</Link></ListItem>                     
              <ListItem key = {2} button divider>
                <Link to="/nylon-rope" className={classes.reslink}>Nylon Rope</Link></ListItem>               
               <ListItem key = {3} button divider>
               <Link to="/double-braided-nylon" className={classes.reslink}>Double-Braided Nylon</Link></ListItem>                            
               <ListItem key = {4} button divider>
               <Link to="/solid-braid-nylon" className={classes.reslink}>Solid-Braid Nylon</Link></ListItem>  
               <ListItem key = {5} button divider>
               <Link to="/anchor-lines" className={classes.reslink}>Anchor Lines</Link></ListItem>    
               <ListItem key = {6} button divider>
               <Link to="/dock-lines" className={classes.reslink}>Dock Lines</Link></ListItem>     
               <ListItem key = {7} button divider>
               <Link to="/polypropylene-rope" className={classes.reslink}>Polypropylene Rope</Link></ListItem>   
               <ListItem key = {8} button divider>
               <Link to="/double-braided-polyester" className={classes.reslink}>Double-Braided Polyester</Link></ListItem>       
               <ListItem key = {9} button divider>
               <Link to="/sea-strand-x" className={classes.reslink}>Sea-Strand X</Link></ListItem>       
               <ListItem key = {10} button divider> 
               <Link to="/" className={classes.reslink2}>Home</Link> </ListItem>
               <ListItem key = {11} button divider>
               <a href="/#contact" className={classes.reslink2}>Contact</a></ListItem> 
               <ListItem key = {12} button divider>
               <a href="/#about" className={classes.reslink2}>About</a> </ListItem>
               <ListItem key = {13} button divider>
               <a href="/#where-to-buy" className={classes.reslink2}>Where to Buy</a> </ListItem>
               <ListItem key = {14} button divider>
               <a target="_blank" rel="noopener noreferrer" href="https://e-rigging.com/collections/rope" className={classes.reslink2}>Products</a> </ListItem>
              
      </List>
    </div>
  );

  return (
    <div>             
       <Box	display={{ xs: 'block', md: 'none', sm: 'block', lg: 'none' }} >
      {['left'].map(anchor => (
        <React.Fragment key={anchor}>
            <AppBar  className={classes.color} >        
          <Toolbar >
            <Grid container direction = "row" justify = "space-between"  >            
              <MenuIcon style={{fontSize:'30px', color: 'white', paddingTop:'6px'}} onClick={toggleDrawer(anchor, true)}>{anchor}/></MenuIcon>          
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            {list(anchor)}
          </Drawer>
          <Link to="/"> 
          <img src={logo} className={classes.reslogo} alt=""/></Link>
          </Grid>
          </Toolbar>
        </AppBar>
        </React.Fragment>
      ))}
      </Box>
    </div>
  );
}