import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import carousel1 from '../assets/carousel1.png'
import carousel2 from '../assets/sea-strand-x-banner.png'
import manilaBanner from '../assets/manila-rope-banner.png'
import dockLinesBanner from '../assets/dock-lines-banner.png'
import anchorLinesBanner from '../assets/anchor-lines-banner.png'
import Backtotop from './Backtotop'
import About from './About';
import Contact from './Contact';
import Wheretobuy from './Wheretobuy';
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Helmet } from 'react-helmet'

const arrowStyles = {
  position: "absolute",
  zIndex: 2,
  // top: 'calc(50% - 15px)',
  top: "5%",
  width: "3%",
  height: "100%",
  color: "#0060A6",
  cursor: "pointer",
  "&hover": {
    top: 0,
    bottom: 0,
    backgroundColor: "lightgrey",
    opacity: ".4",
  },
};

function Home () {     
  return (
    <div>      
               <Helmet>
           <title>Sea-Strand</title>
         </Helmet>      
     <Backtotop/>                                              
     <Carousel autoPlay 
      transitionTime={1000} 
      interval={8000}
      infiniteLoop 
      showThumbs={false}
      showStatus={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <ArrowBackIosIcon
            onClick={onClickHandler}
            title={label}
            style={{ ...arrowStyles, left: 15 }}
          />
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <ArrowForwardIosIcon
            onClick={onClickHandler}
            title={label}
            style={{ ...arrowStyles, right: 15 }}
          />
        )
      }
      >
      <div><img src={carousel1} alt="" /></div>
      {/* <div><img src={carousel2} alt=""/></div> */}
      {/* <div><img src={carousel3} alt=""/></div>  */}
      <div><img src={anchorLinesBanner} alt=""/></div> 
      <div><img src={manilaBanner} alt=""/></div> 
      <div><img src={dockLinesBanner} alt=""/></div> 
      
      </Carousel>   
      <Wheretobuy/>              
      <Contact/> 
      <About/>  
   </div>
    );
   }
 export default Home;
