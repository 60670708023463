import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Topnav from "../components/Topnav";
import Scroll from "./Scroll";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  tree: {
    padding: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      display: "block",
    },
  },
  list: {
    width: 200,
    fontFamily: "Lato",
  },
  sideBarIcon: {
    color: "white",
    cursor: "pointer",
  },
  link: {
    fontFamily: "Lato",
    fontSize: "15px",
    textDecoration: "none",
    color: "#ebebe6",
    display: "flex",
    justifyContent: "space-around",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .3s ease",
    },
  },
  menuLink: {
    fontFamily: "Lato",
    fontSize: "15px",
    textDecoration: "none",
    color: "#ebebe6",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .3s ease",
      backgroundColor: "#0060A6",
    },
  },
  menuItem: {
    fontFamily: "Lato",
    fontSize: "15px",
    textDecoration: "none",
    color: "black",
    display: "flex",
    justifyContent: "space-around",
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .3s ease",
    },
  },
}));

function ElevateAppBar() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Topnav />
      <AppBar
        className={classes.root}
        position="sticky"
        style={{ backgroundColor: "#0060a6" }}
      >
        <Toolbar style={{ minHeight: "50px" }}>
          <Typography align="center">
            <Scroll />
          </Typography>

          <Typography
            variant="subheading"
            color="inherit"
            style={{ flexGrow: 1 }}
          >
            <Link to="/manila-rope" className={classes.link} style={{paddingLeft: '5px'}}>
              MANILA ROPE
            </Link>
          </Typography>
          <Typography
            variant="subheading"
            color="inherit"
            style={{ flexGrow: 1 }}
          >
            <div style={{ justifyContent: "center", textAlign: "center" }}>
              <Button
                className={classes.menuLink}
                style={{ marginTop: "2px" }}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                onMouseOver={handleClick}
              >
                Nylon Rope
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ onMouseLeave: handleClose }}
                style={{ textAlign: "center" }}
              >
                <MenuItem onClick={handleClose} >
                  <Link to="/nylon-rope" className={classes.menuItem}>
                    3-STRAND NYLON
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link to="/double-braided-nylon" className={classes.menuItem}>
                    DOUBLE-BRAIDED NYLON
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Link to="/solid-braid-nylon" className={classes.menuItem}>
                    SOLID-BRAID NYLON
                  </Link>
                </MenuItem>
              </Menu>
            </div>
          </Typography>
          <Typography
            variant="subheading"
            color="inherit"
            style={{ flexGrow: 1 }}
          >
            <Link to="/anchor-lines" className={classes.link}>
              ANCHOR LINES
            </Link>
          </Typography>
          <Typography
            variant="subheading"
            color="inherit"
            style={{ flexGrow: 1 }}
          >
            <Link to="/dock-lines" className={classes.link}>
              DOCK LINES
            </Link>
          </Typography>
          <Typography
            variant="subheading"
            color="inherit"
            style={{ flexGrow: 1 }}
          >
            <Link to="/polypropylene-rope" className={classes.link}>
              POLYPROPYLENE ROPE
            </Link>
          </Typography>
          <Typography
            variant="subheading"
            color="inherit"
            style={{ flexGrow: 1 }}
          >
            <Link to="/double-braided-polyester" className={classes.link}>
              DOUBLE-BRAIDED POLYESTER
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

export default ElevateAppBar;
