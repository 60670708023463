import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import styles from "./styles/ProductStyles";
import ModalImage from "react-modal-image";
import Wheretobuy from "./Wheretobuy2";
import DescriptionComponent from "./DescriptionComponent";
import ManilaBanner from '../assets/manila-rope-banner.png'
import { Helmet } from 'react-helmet'

const ManilaRope = () => {
  const classes = styles();
  return (
    <div>
   
<Helmet>
<title>Manila Rope</title>
</Helmet>   
        <Typography
          align="center"
          className={classes.pagetitle}
          style={{backgroundImage: ManilaBanner}}
        >
          Manila Rope
        </Typography>
      <Container maxWidth="md" component="main" className={classes.main}>
    
       
        <Grid container spacing={4} justify="center" alignItems="center" >
          <Grid item xs={10} md={6} sm={4}>
            <div title="One inch by three hundred manila rope">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/one-inch-x-three-hundred-manila-rope.jpg?v=1646856723"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/one-inch-x-three-hundred-manila-rope.jpg?v=1646856723"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="One inch by three hundred manila rope"
            />
            </div>
          </Grid>
          <Grid item xs={10} md={6} sm={4}>
            <div title="One inch by three hundred manila rope strand">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/one-inch-300-rope-strand.jpg?v=1659472534"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/one-inch-300-rope-strand.jpg?v=1659472534"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="One inch by three hundred manila rope strand"
            />
            </div>
          </Grid>
        </Grid>
          <DescriptionComponent
            product="Manila Rope" 
            bgColor="#0060A6"
            subtitle=" Sea-Strand manila ropes are available in 3-strand twisted
            construction, made of 100% natural fiber."
            p1="Manila rope is perfect for many rugged and outdoor uses, like
            landscaping, rope railing, wrapping boat piers, exercise equipment,
            and other decorative outdoor and arts and crafts uses. Manila natural
            fiber rope can be stored indoors or outdoors in a dry, ventilated
            place."
            p2="Our manila fiber rope sizes include 1/4”, 3/8”, 1/2”, 5/8”, 3/4”, and
            1”, with lengths of 300 and 600 feet. All of our manila ropes are
            undyed, with a natural brown color."
          />

        

        <Typography
          align="center"
          className={classes.pagesubtitle}
          gutterBottom
        >
          Manila 3-Strand Rope
        </Typography>

        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#0060A6" }}>
              <TableRow>
                <TableCell className={classes.theader}>
                  Part Number
                </TableCell>
                <TableCell   className={classes.theader}>
                  Diameter
                </TableCell>
                <TableCell   className={classes.theader}>
                  Length
                </TableCell>
                <TableCell  style={{width: "170px"}}  className={classes.theader}>
                  Min Breaking Load
                </TableCell>
                <TableCell   className={classes.theader}>
                  Weight
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tcell}>91402005</TableCell>
                <TableCell   className={classes.tcell}>1/4"</TableCell>
                <TableCell   className={classes.tcell}>600 ft</TableCell>
                <TableCell   className={classes.tcell}>540 lbs</TableCell>
                <TableCell   className={classes.tcell}>12 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91402015</TableCell>
                <TableCell   className={classes.tcell}>3/8"</TableCell>
                <TableCell   className={classes.tcell}>600 ft</TableCell>
                <TableCell   className={classes.tcell}>1215 lbs</TableCell>
                <TableCell   className={classes.tcell}>22 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell  className={classes.tcell}>91402017</TableCell>
                <TableCell   className={classes.tcell}>1/2"</TableCell>
                <TableCell   className={classes.tcell}>300 ft</TableCell>
                <TableCell   className={classes.tcell}>2385 lbs</TableCell>
                <TableCell   className={classes.tcell}>19 lbs</TableCell>
              </TableRow>
              <TableRow>
              <TableCell className={classes.tcell}>91402020</TableCell>
                <TableCell   className={classes.tcell}>1/2"</TableCell>
                <TableCell   className={classes.tcell}>600 ft</TableCell>
                <TableCell   className={classes.tcell}>2385 lbs</TableCell>
                <TableCell   className={classes.tcell}>45 lbs</TableCell>
              </TableRow>
              <TableRow>
              <TableCell className={classes.tcell}>91402022</TableCell>
                <TableCell   className={classes.tcell}>5/8"</TableCell>
                <TableCell   className={classes.tcell}>300 ft</TableCell>
                <TableCell   className={classes.tcell}>3960 lbs</TableCell>
                <TableCell   className={classes.tcell}>35 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91402025</TableCell>
                <TableCell   className={classes.tcell}>5/8"</TableCell>
                <TableCell   className={classes.tcell}>600 ft</TableCell>
                <TableCell   className={classes.tcell}>3960 lbs</TableCell>
                <TableCell   className={classes.tcell}>70 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91402027</TableCell>
                <TableCell   className={classes.tcell}>3/4"</TableCell>
                <TableCell   className={classes.tcell}>300 ft</TableCell>
                <TableCell   className={classes.tcell}>4860 lbs</TableCell>
                <TableCell   className={classes.tcell}>44 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91402030</TableCell>
                <TableCell   className={classes.tcell}>3/4"</TableCell>
                <TableCell   className={classes.tcell}>600 ft</TableCell>
                <TableCell   className={classes.tcell}>4860 lbs</TableCell>
                <TableCell   className={classes.tcell}>90 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91402037</TableCell>
                <TableCell   className={classes.tcell}>1"</TableCell>
                <TableCell   className={classes.tcell}>300 ft</TableCell>
                <TableCell   className={classes.tcell}>8100 lbs</TableCell>
                <TableCell   className={classes.tcell}>74 lbs</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Wheretobuy />
    </div>
  );
};

export default ManilaRope;
