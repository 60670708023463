import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import styles from "./styles/ProductStyles";
import ModalImage from "react-modal-image";
import Wheretobuy from "./Wheretobuy2";
import DescriptionComponent from "./DescriptionComponent";
import { Helmet } from 'react-helmet'

const DoubleBraidedPolyester = () => {
  const classes = styles();
  return (
    <div>
   
    <Helmet>
      <title>Double Braided Polyester</title>
    </Helmet>   
        <Typography
          align="center"
          className={classes.pagetitle}
        >
          Double Braided Polyester
        </Typography>
      <Container maxWidth="md" component="main" className={classes.main}>
        <Grid container spacing={4} justify="center" aignItems="center">
          <Grid item xs={10} md={6} sm={4}>
            <div title="Double Braided Polyester Rope">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/products/three-eighths-dock-line.jpg"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/products/three-eighths-dock-line.jpg"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Double Braided Polyester Rope"
            />
            </div>
          </Grid>
          <Grid item xs={10} md={6} sm={4}>
            <div title="Double Braided Polyester Rope Closeup">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/Double-Braid-Polyester-Rope-Closeup.jpg?v=1659477267"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/Double-Braid-Polyester-Rope-Closeup.jpg?v=1659477267"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Double Braided Polyester Rope Closeup"
            />
            </div>
          </Grid>
        </Grid>
        <DescriptionComponent 
          product="Double Braided Polyester"
          subtitle="Sea-Strand polyester ropes are available with double-braid construction, also known as “yacht braid.” "
          p1="These double-braided ropes are very strong and resist “hockling,” or kinking and unraveling. Polyester ropes are typically used for boat rigging, as guy lines, or as arborist and bull rope. With a thimble or splice, these ropes make great anchor ropes or dock lines.
          "
          p2="Our high-quality polyester rope is strong and more comfortable to handle than typical 3-strand rope. It’s also UV- and chemical-resistant with great wet and dry performance for reliable performance time after time."
          p3="Double-braided polyester rope sizes include 1/4”, 3/8”, 1/2”, 5/8”, and 3/4”, with lengths of 300 and 600 feet. These ropes are white with colored tracers.
          "
        />

        <Typography
          align="center"
          className={classes.pagesubtitle}
          gutterBottom
        >
          Double Braided Polyester Rope
        </Typography>

        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#0060A6" }}>
              <TableRow>
              <TableCell className={classes.theader}>
                  Part Number
                </TableCell>
              <TableCell className={classes.theader}>
                  Diameter
                </TableCell>
                <TableCell className={classes.theader}>
                  Length
                </TableCell>
                <TableCell width="170px" className={classes.theader}>
                  Min Breaking Load
                </TableCell>
                <TableCell className={classes.theader}>
                  Weight
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
              
               <TableCell className={classes.tcell}>91404005</TableCell>
               <TableCell className={classes.tcell}>1/4"	</TableCell>
               <TableCell className={classes.tcell}>600 ft	</TableCell>
               <TableCell className={classes.tcell}>1035 lbs	</TableCell>
               <TableCell className={classes.tcell}>12 lbs</TableCell>
              </TableRow>
              <TableRow>
              
               <TableCell className={classes.tcell}>91404007</TableCell>
               <TableCell className={classes.tcell}>3/8"	</TableCell>
               <TableCell className={classes.tcell}>300 ft	</TableCell>
               <TableCell className={classes.tcell}>4275 lbs	</TableCell>
               <TableCell className={classes.tcell}>14 lbs</TableCell>
              </TableRow>
              <TableRow>
              
               <TableCell className={classes.tcell}>91404010</TableCell>
               <TableCell className={classes.tcell}>3/8"	</TableCell>
               <TableCell className={classes.tcell}>600 ft	</TableCell>
               <TableCell className={classes.tcell}>4275 lbs	</TableCell>
               <TableCell className={classes.tcell}>27 lbs</TableCell>
              </TableRow>
              <TableRow>
              
               <TableCell className={classes.tcell}>91404012</TableCell>
               <TableCell className={classes.tcell}>1/2"	</TableCell>
               <TableCell className={classes.tcell}>300 ft	</TableCell>
               <TableCell className={classes.tcell}>7425 lbs	</TableCell>
               <TableCell className={classes.tcell}>24 lbs</TableCell>
              </TableRow>
              <TableRow>
              
               <TableCell className={classes.tcell}>91404015</TableCell>
               <TableCell className={classes.tcell}>1/2"	</TableCell>
               <TableCell className={classes.tcell}>600 ft	</TableCell>
               <TableCell className={classes.tcell}>7425 lbs	</TableCell>
               <TableCell className={classes.tcell}>48 lbs</TableCell>
              </TableRow>
              <TableRow>
              
               <TableCell className={classes.tcell}>91404017</TableCell>
               <TableCell className={classes.tcell}>5/8"	</TableCell>
               <TableCell className={classes.tcell}>300 ft	</TableCell>
               <TableCell className={classes.tcell}>11250 lbs	</TableCell>
               <TableCell className={classes.tcell}>38 lbs</TableCell>
              </TableRow>
              <TableRow>
              
               <TableCell className={classes.tcell}>91404020</TableCell>
               <TableCell className={classes.tcell}>5/8"	</TableCell>
               <TableCell className={classes.tcell}>600 ft	</TableCell>
               <TableCell className={classes.tcell}>11250 lbs	</TableCell>
               <TableCell className={classes.tcell}>75 lbs</TableCell>
              </TableRow>
              <TableRow>
              
               <TableCell className={classes.tcell}>91404022</TableCell>
               <TableCell className={classes.tcell}>3/4"	</TableCell>
               <TableCell className={classes.tcell}>300 ft	</TableCell>
               <TableCell className={classes.tcell}>16000 lbs	</TableCell>
               <TableCell className={classes.tcell}>54 lbs</TableCell>
              </TableRow>
              <TableRow>
              
               <TableCell className={classes.tcell}>91404025</TableCell>
               <TableCell className={classes.tcell}>3/4"	</TableCell>
               <TableCell className={classes.tcell}>600 ft	</TableCell>
               <TableCell className={classes.tcell}>16000 lbs	</TableCell>
               <TableCell className={classes.tcell}>108 lbs</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Wheretobuy />
    </div>
  );
};

export default DoubleBraidedPolyester;
