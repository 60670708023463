import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import styles from "./styles/ProductStyles";
import ModalImage from "react-modal-image";
import Wheretobuy from "./Wheretobuy2";
import DescriptionComponent from './DescriptionComponent'
import { Helmet } from 'react-helmet'

const AnchorLines = () => {
  const classes = styles();
  return (
    <div>
    
<Helmet>
<title>Anchor Liens</title>
</Helmet>   
      <Typography
        align="center"
        className={classes.pagetitle}
      >
        Anchor Lines
      </Typography>
      <Container maxWidth="md" component="main" className={classes.main}>
        <Typography
          className={classes.pagesubtitle}
          style={{ textAlign: "center" }}
        >
          
        </Typography>
        <Grid container spacing={4} justify="center" aignItems="center">
          <Grid item xs={10} md={6} sm={4}>
            <div title="Polyester Double Braided Anchor Line">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-eighths-x-two-hundred-polyester-double-braided-anchor-line.jpg?v=1646856733"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-eighths-x-two-hundred-polyester-double-braided-anchor-line.jpg?v=1646856733"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Polyester Double Braided Anchor Line"
            />
            </div>
          </Grid>
          <Grid item xs={10} md={6} sm={4}>
            <div title="Polyester Double Braided Anchor Line Closeup">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/Polyester-Double-Braided-Anchor-Line-close.jpg?v=1659477528"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/Polyester-Double-Braided-Anchor-Line-close.jpg?v=1659477528"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Polyester Double Braided Anchor Line Closeup"
            />
            </div>
          </Grid>
        </Grid>
        <DescriptionComponent 
          product="Anchor Lines"
          subtitle="Sea-Strand anchor lines are constructed of double-braided polyester
          anchor rope with a pre-installed 316 stainless steel thimble for quick
          connection to an anchor or chain."
          p1="   The double-braid polyester rope is torque-free and has excellent
          abrasion resistance, important features for continuous use setting and
          weighing anchor."
          p2=" Our high-quality anchor rope is strong and more comfortable to handle
          than typical 3-strand rope. It’s also UV- and chemical-resistant with
          great wet and dry performance for reliable performance time after
          time."
          p3="  Polyester anchor rope sizes include 3/8”, 1/2”, and 5/8”, with lengths
          of 100, 150, 200, and 250 feet. These ropes are white with blue and
          yellow tracers."
        />


        <Typography
          align="center"
          className={classes.pagesubtitle}
          gutterBottom
        >
          Double Braided Polyester Anchor Lines
        </Typography>

        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#0060A6" }}>
              <TableRow>
              <TableCell className={classes.theader}>
                  Part Number
                </TableCell>
                <TableCell className={classes.theader}>
                  Diameter
                </TableCell>
                <TableCell className={classes.theader}>
                  Length
                </TableCell>
                <TableCell width="170px" className={classes.theader}>
                  Min Breaking Load
                </TableCell>
                <TableCell className={classes.theader}>
                  Weight
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tcell}> 91406550</TableCell>
                <TableCell className={classes.tcell}>3/8"</TableCell>
                <TableCell className={classes.tcell}>100 ft</TableCell>
                <TableCell className={classes.tcell}>2900 lbs</TableCell>
                <TableCell className={classes.tcell}>4.6 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91406552</TableCell>
                <TableCell className={classes.tcell}>3/8"</TableCell>
                <TableCell className={classes.tcell}>150 ft</TableCell>
                <TableCell className={classes.tcell}>2900 lbs</TableCell>
                <TableCell className={classes.tcell}>7.05 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91406554</TableCell>
                <TableCell className={classes.tcell}>3/8"</TableCell>
                <TableCell className={classes.tcell}>200 ft</TableCell>
                <TableCell className={classes.tcell}>2900 lbs</TableCell>
                <TableCell className={classes.tcell}>8.9 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91406556</TableCell>
                <TableCell className={classes.tcell}>1/2"</TableCell>
                <TableCell className={classes.tcell}>100 ft</TableCell>
                <TableCell className={classes.tcell}>5085 lbs</TableCell>
                <TableCell className={classes.tcell}>7.65 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91406558</TableCell>
                <TableCell className={classes.tcell}>1/2"</TableCell>
                <TableCell className={classes.tcell}>150 ft</TableCell>
                <TableCell className={classes.tcell}>5085 lbs</TableCell>
                <TableCell className={classes.tcell}>11.35 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91406560</TableCell>
                <TableCell className={classes.tcell}>1/2"</TableCell>
                <TableCell className={classes.tcell}>200 ft</TableCell>
                <TableCell className={classes.tcell}>5085 lbs</TableCell>
                <TableCell className={classes.tcell}>15.30 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91406562</TableCell>
                <TableCell className={classes.tcell}>1/2"</TableCell>
                <TableCell className={classes.tcell}>250 ft</TableCell>
                <TableCell className={classes.tcell}>5085 lbs</TableCell>
                <TableCell className={classes.tcell}>18.75 lbs</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tcell}>91406564</TableCell>
                <TableCell className={classes.tcell}>5/8"</TableCell>
                <TableCell className={classes.tcell}>250 ft</TableCell>
                <TableCell className={classes.tcell}>7825 lbs</TableCell>
                <TableCell className={classes.tcell}>30.2 lbs</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Wheretobuy />
    </div>
  );
};

export default AnchorLines;
