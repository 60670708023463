import React from 'react'
import {  Container, Typography, Grid } from '@material-ui/core';
import styles from './styles/AboutStyles'
import aboutImg from './../assets/about-img.png'


export default function About() {
    const classes = styles();
    return (
        <div
        id="about"
        className={classes.aboutDiv}
      >
        <Container
          maxWidth="lg"
          style={{
            flexGrow: "1",
          }}
        >
          <Typography align="center" className={classes.pagetitle}>
            About Us
          </Typography>
  
          <div style={{ flexBasis: "none" }}>
            <Grid container>
              <Grid
                item
                lg={5}
                xs={12}
                md={4}
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                <img src={aboutImg} className={classes.aboutimg} alt="aboutimg" /> 
              </Grid>
  
              <Grid item lg={7} xs={12} md={8} style={{ padding: "0%" }}>
                <Typography className={classes.pagecontent} component="p">
                Here at Sea-Strand, we believe that high-quality rope shouldn’t mean high prices, and low prices shouldn’t mean low quality. We created our line of marine rope products to use the highest-quality materials possible for a variety of applications. 
                </Typography>
                <Typography className={classes.pagecontent} component="p">
                Our years of engineering expertise translates to strict manufacturing requirements. Our logistics experience means we use the highest-quality materials at the best prices available. The result is a line of competitively-priced ropes that consistently perform to the same high standards, every time.
                </Typography>
                <Typography className={classes.pagecontent} component="p">
                Sea-Strand lines and ropes are engineered with a variety of materials for reliable high performance in a variety of environments and applications.
                </Typography>
                <Typography className={classes.pagecontent} component="p">
                Sea-Strand ropes are products of Sea‐Land Distributors, LLC.
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    )
}
