import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import styles from "./styles/ProductStyles";
import ModalImage from "react-modal-image";
import Wheretobuy from "./Wheretobuy2";
import DescriptionComponent from './DescriptionComponent'
import { Helmet } from 'react-helmet'

const DockLines = () => {
  const classes = styles();
  return (
    <div>

<Helmet>
<title>Dock Lines</title>
</Helmet>   
   
        <Typography
          align="center"
          className={classes.pagetitle}
        >
          Dock Lines
        </Typography>
      <Container maxWidth="md" component="main" className={classes.main}>
        <Typography
          className={classes.pagesubtitle}
          style={{ textAlign: "center" }}
        >
          
        </Typography>
        <Grid container spacing={4} justify="center" aignItems="center">
          <Grid item xs={10} md={6} sm={4}>
            <div  title="Dock Line Rope">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-eighths-dock-line.jpg?v=1646856728"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-eighths-dock-line.jpg?v=1646856728"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Dock Line Rope"
            />
            </div>
          </Grid>
          <Grid item xs={10} md={6} sm={4}>
            <div   title="Dock Line Rope Closeup">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-eighths-x-twenty-ft-double-braid-nylon-dock-line-white-USA-opt2.jpg?v=1659477664"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/three-eighths-x-twenty-ft-double-braid-nylon-dock-line-white-USA-opt2.jpg?v=1659477664"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Dock Line Rope Closeup"
            />
            </div>
          </Grid>
        </Grid>

        <DescriptionComponent 
          product="Dock Lines"
          subtitle="Sea-Strand dock lines are constructed of double-braided polyester rope
          with a pre-spliced 12-inch eye so you can put it to work right away."
          p1="The double-braid polyester rope is torque-free and has excellent
          abrasion resistance, important features for continuous use during
          mooring and other marine activities."
          p2="  Our high-quality mooring rope is strong and more comfortable to handle
          than typical 3-strand rope. It’s also UV- and chemical-resistant with
          great wet and dry performance for reliable performance time after
          time."
          p3="  Polyester anchor rope sizes include 3/8”, 1/2”, and 5/8”, with lengths
          of 100, 150, 200, and 250 feet. These ropes are white with blue and
          yellow tracers."
        />


        <Typography
          align="center"
          className={classes.pagesubtitle}
          gutterBottom
        >
          Double Braided Polyester Dock Lines
        </Typography>

        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#0060A6" }}>
              <TableRow>
              <TableCell className={classes.theader}>
                  Part Number
                </TableCell>
              <TableCell  className={classes.theader}>
                  Diameter
                </TableCell>
                <TableCell  className={classes.theader}>
                  Length
                </TableCell>
                <TableCell width="170px"  className={classes.theader}>
                  Min Breaking Load
                </TableCell>
                <TableCell  className={classes.theader}>
                  Weight
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
              
              <TableCell className={classes.tcell}>91406502	</TableCell>
              <TableCell className={classes.tcell}>3/8"	</TableCell>
              <TableCell className={classes.tcell}>15 ft	</TableCell>
              <TableCell className={classes.tcell}>2900 lbs	</TableCell>
              <TableCell className={classes.tcell}>0.675 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91406504</TableCell>
              <TableCell className={classes.tcell}>3/8"	</TableCell>
              <TableCell className={classes.tcell}>20 ft	</TableCell>
              <TableCell className={classes.tcell}>2900 lbs	</TableCell>
              <TableCell className={classes.tcell}>0.942 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91406506</TableCell>
              <TableCell className={classes.tcell}>3/8"	</TableCell>
              <TableCell className={classes.tcell}>25 ft	</TableCell>
              <TableCell className={classes.tcell}>2900 lbs	</TableCell>
              <TableCell className={classes.tcell}>1.082 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91406508</TableCell>
              <TableCell className={classes.tcell}>1/2"	</TableCell>
              <TableCell className={classes.tcell}>15 ft	</TableCell>
              <TableCell className={classes.tcell}>5085 lbs	</TableCell>
              <TableCell className={classes.tcell}>1.172 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91406510</TableCell>
              <TableCell className={classes.tcell}>1/2"	</TableCell>
              <TableCell className={classes.tcell}>20 ft	</TableCell>
              <TableCell className={classes.tcell}>5085 lbs	</TableCell>
              <TableCell className={classes.tcell}>1.523 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91406512</TableCell>
              <TableCell className={classes.tcell}>1/2"	</TableCell>
              <TableCell className={classes.tcell}>25 ft	</TableCell>
              <TableCell className={classes.tcell}>5085 lbs	</TableCell>
              <TableCell className={classes.tcell}>1.886 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91406514</TableCell>
              <TableCell className={classes.tcell}>5/8"	</TableCell>
              <TableCell className={classes.tcell}>25 ft	</TableCell>
              <TableCell className={classes.tcell}>7825 lbs	</TableCell>
              <TableCell className={classes.tcell}>2.948 lbs</TableCell>
              </TableRow>
              
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Wheretobuy />
    </div>
  );
};

export default DockLines;
