import React from 'react'
import { Paper, Typography } from "@material-ui/core";
import styles from "./styles/ProductStyles";

const DescriptionComponent = ({ product, p1, p2, p3, p4, p5, subtitle}) => {
  const classes = styles();
  return (
    <>
    <div className={classes.descriptionDiv}>
        <Typography
          align="left"
          className={classes.pagesubtitle}
          style={{ fontFamily: "Lato",padding: "30px 0px", color: "white", marginBottom: 0 }}
        >
          {product} Description
        </Typography>
        </div>
        <Paper style={{padding: "17px"}}>
        <Typography
          className={classes.pagesubtitle}
          style={{ textAlign: "left" }}
        >
          {subtitle}
        </Typography>
        <Typography className={classes.pagecontent} style={{marginTop: 0}}>
          {p1}
        </Typography>
        <Typography className={classes.pagecontent}>
          {p2}
        </Typography>
        <Typography className={classes.pagecontent}>
          {p3}
        </Typography>
        <Typography className={classes.pagecontent}>
          {p4}
        </Typography>
        <Typography className={classes.pagecontent}>
          {p5}
        </Typography>
        </Paper>
    </>
  )
}

export default DescriptionComponent
