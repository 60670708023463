import React from "react";
import { Container, Typography, Grid } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import styles from "./styles/ProductStyles";
import ModalImage from "react-modal-image";
import Wheretobuy from "./Wheretobuy2";
import DescriptionComponent from './DescriptionComponent'
import { Helmet } from 'react-helmet'
 
const DoubleBraidedNylon = () => {
  const classes = styles();
  return (
    <div>
 
<Helmet>
<title>Double Braided Nylon</title>
</Helmet>   
      <Typography
        align="center"
        className={classes.pagetitle}
      >
        Double Braided Nylon
      </Typography>
      <Container maxWidth="md" component="main" className={classes.main}>
        <Grid container spacing={4} justify="center" aignItems="center">
          <Grid item xs={10} md={6} sm={4}>
            <div  title="Double Braided Nylon Rope">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/five-eighths-x-twenty-ft-double-braid-nylon-dock-line-white-USA-opt2.jpg?v=1659477662"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/five-eighths-x-twenty-ft-double-braid-nylon-dock-line-white-USA-opt2.jpg?v=1659477662"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Double Braided Nylon Rope"
            />
            </div>
          </Grid>
          <Grid item xs={10} md={6} sm={4}>
            <div  title="Double Braided Nylon Rope Closeup">
            <ModalImage
              small="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/Double-Braid-Nylon-Rope-Closeup.jpg?v=1659477263"
              large="https://cdn.shopify.com/s/files/1/0553/8614/4841/files/Double-Braid-Nylon-Rope-Closeup.jpg?v=1659477263"
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="Double Braided Nylon Rope Closeup"
            />
            </div>
          </Grid>
        </Grid>

        <DescriptionComponent 
          product="Double Braided Nylon"
          subtitle=" Sea-Strand nylon ropes are designed for great all-around performance."
          p1="Our 100% nylon construction gives excellent overall strength, flexibility, and shock load tolerance. Nylon ropes are typically used for marine and other outdoor applications like dock lines and anchor ropes, mooring lines, and horse tack like halters and reins.
          "
          p2="Our marine-grade nylon ropes have a smooth feel for comfort during repeated handling, and are easy to splice for applications like mooring lines or installing thimbles for anchor shackles.
          "
          p3="SDouble-braided nylon rope sizes include 1/4”, 3/8”, 1/2”, 5/8”, and 3/4”, with lengths of 300 and 600 feet. These ropes are white with colored tracers.
          "
        />
       

        <Typography
          align="center"
          className={classes.pagesubtitle}
          gutterBottom
        >
          Double Braided Nylon Rope
        </Typography>

        <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
          <Table
            className={classes.table}
            aria-label="simple table"
            style={{ padding: "5px" }}
          >
            <TableHead style={{ backgroundColor: "#0060A6" }}>
              <TableRow>
              <TableCell className={classes.theader}>
                  Part Number
                </TableCell>
              <TableCell className={classes.theader}>
                  Diameter
                </TableCell>
                <TableCell className={classes.theader}>
                  Length
                </TableCell>
                <TableCell width="170px" className={classes.theader}>
                  Min Breaking Load
                </TableCell>
                <TableCell className={classes.theader}>
                  Weight
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
              
              <TableCell className={classes.tcell}>91403005</TableCell>
              <TableCell className={classes.tcell}>1/4"	</TableCell>
              <TableCell className={classes.tcell}>600 ft	</TableCell>
              <TableCell className={classes.tcell}>2000 lbs	</TableCell>
              <TableCell className={classes.tcell}>15.30 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91403007</TableCell>
              <TableCell className={classes.tcell}>3/8"	</TableCell>
              <TableCell className={classes.tcell}>300 ft	</TableCell>
              <TableCell className={classes.tcell}>4400 lbs	</TableCell>
              <TableCell className={classes.tcell}>11.10 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91403010</TableCell>
              <TableCell className={classes.tcell}>3/8"	</TableCell>
              <TableCell className={classes.tcell}>600 ft	</TableCell>
              <TableCell className={classes.tcell}>4400 lbs	</TableCell>
              <TableCell className={classes.tcell}>22.20 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91403012</TableCell>
              <TableCell className={classes.tcell}>1/2"	</TableCell>
              <TableCell className={classes.tcell}>300 ft	</TableCell>
              <TableCell className={classes.tcell}>7800 lbs	</TableCell>
              <TableCell className={classes.tcell}>19.80 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91403015</TableCell>
              <TableCell className={classes.tcell}>1/2"	</TableCell>
              <TableCell className={classes.tcell}>600 ft	</TableCell>
              <TableCell className={classes.tcell}>7800 lbs	</TableCell>
              <TableCell className={classes.tcell}>39.60 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91403017</TableCell>
              <TableCell className={classes.tcell}>5/8"	</TableCell>
              <TableCell className={classes.tcell}>300 ft	</TableCell>
              <TableCell className={classes.tcell}>12200 lbs	</TableCell>
              <TableCell className={classes.tcell}>31.20 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91403020</TableCell>
              <TableCell className={classes.tcell}>5/8"	</TableCell>
              <TableCell className={classes.tcell}>600 ft</TableCell>
              <TableCell className={classes.tcell}>	12200 lbs	</TableCell>
              <TableCell className={classes.tcell}>62.40 lbs</TableCell>
              </TableRow>
              <TableRow>
              
              <TableCell className={classes.tcell}>91403022</TableCell>
              <TableCell className={classes.tcell}>3/4"	</TableCell>
              <TableCell className={classes.tcell}>300 ft</TableCell>
              <TableCell className={classes.tcell}>	17350 lbs	</TableCell>
              <TableCell className={classes.tcell}>45.00 lbs</TableCell>
              </TableRow>
              <TableRow>
             
             <TableCell className={classes.tcell}>91403025</TableCell>
             <TableCell className={classes.tcell}> 3/4"	</TableCell>
              <TableCell className={classes.tcell}>600 ft</TableCell>
              <TableCell className={classes.tcell}>	17350 lbs	</TableCell>
              <TableCell className={classes.tcell}>90.00 lbs</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Wheretobuy />
    </div>
  );
};

export default DoubleBraidedNylon;
